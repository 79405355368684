import { appRoutes } from './app.routes';
import { BrowserModule, HammerGestureConfig, HammerModule, HAMMER_GESTURE_CONFIG, Title } from '@angular/platform-browser';
import { APP_INITIALIZER, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { CommonService } from './shared/core/services/common.service';
import { HttpService } from './shared/core/services/http-service.service';
import { DropdownsService } from './shared/core/services/dropdowns.service';
import { StorageService } from './shared/core/services/storage.service';
import { ErrorsHandlerService } from './shared/core/services/errors.handler.service';
import { ConfigService } from './shared/core/services/config.service';
import { AuthGuard } from './shared/core/guards/auth.guard';
import { RoleGuard } from './shared/core/guards/role.guard';
import { DatePipe } from '@angular/common';
// import { AuthenticatorService } from './core/services/authenticator.service';
import { AppPreloadingStrategy } from './shared/core/appPreloadingStrategy';
import { HttpAuthInterceptor } from './shared/core/services/http-auth-interceptor';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
// import { MAT_DATE_LOCALE } from '@angular/material/core';
import { rollbarFactory, RollbarService } from './shared/core/services/rollbar.service';
import { DialogService } from './shared/layout/dialogs/services/dialog.service';
import { DropdownsModule } from './shared/layout/dropdowns/dropdowns.module';
import { MapModule } from './shared/layout/map/map.module';
import { AlertDialogComponent } from './shared/layout/dialogs/alert-dialog/alert-dialog.component';
import { MsalInterceptorConfiguration, MsalGuardConfiguration, MsalBroadcastService, MsalGuard, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalRedirectComponent } from '@azure/msal-angular';
import { LogLevel, IPublicClientApplication, PublicClientApplication, BrowserCacheLocation, InteractionType } from '@azure/msal-browser';
import { OAuthSettings } from './shared/core/msaloauth';
import { ModalModule } from 'ngx-bootstrap/modal';
import { AuthenticatorService } from './shared/core/services/authenticator.service';
import * as Hammer from 'hammerjs';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { RouterModule } from '@angular/router';
// import { ProfileComponent } from './user/profile/profile.component';
import { MatErrorModule } from './layout/mat-error/mat-error.module';
// import { TripliteModule } from './manage/triplite/triplite.module';
// import { NotificationsModule } from './manage/notifications/notifications.module';
// import { PdfModule } from './pdf/pdf.module';
import { MAT_DATE_LOCALE } from '@angular/material/core';

// import { MsalModule } from '@azure/msal-angular';
// const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
export class MyHammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}
@NgModule({
  declarations: [
    AppComponent,
    // ProfileComponent,

  ],
  imports: [
    RouterModule.forRoot(appRoutes, { enableTracing: false, preloadingStrategy: AppPreloadingStrategy }),

    BrowserModule,
    ModalModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    HttpClientModule,
    HammerModule,
    SharedModule,
    MapModule,
    BrowserAnimationsModule,
    DropdownsModule,
    MatErrorModule,
    // TripliteModule,
    // MapModule,

    // NotificationsModule,
    //apiKey: 'AIzaSyDem6tqqXGpyvFNmgIdVIsmg3VlPZxAwe8',
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    // MsalModule.forRoot(
    //   {
    //   auth: {
    //     clientId: OAuthSettings.appId,
    //     authority: 'https://login.microsoftonline.com/' + OAuthSettings.tenentId + '/',
    //     // redirectUri: 'Enter_the_Redirect_Uri_Here',
    //     // postLogoutRedirectUri: '',
    //   },
    //   cache: {
    //     cacheLocation: 'localStorage',
    //     storeAuthStateInCookie: isIE, // set to true for IE 11
    //   },
    // },
    // {
    //   popUp: !isIE,
    //   consentScopes: [
    //     'user.read',
    //     'user.read.all',
    //     'openid',
    //     'profile',
    //   ],
    //   unprotectedResources: [],
    //   protectedResourceMap: [
    //     ['https://graph.microsoft.com/me', ['user.read.all']]
    //   ],
    //   extraQueryParameters: {}
    // },{})
    // PdfModule,
  ],
  providers: [
    Title,
    CommonService,
    HttpService,
    DropdownsService,
    StorageService,
    ErrorsHandlerService,
    ConfigService,
    DialogService,
    AuthGuard, RoleGuard, DatePipe,
    AuthenticatorService,
    // EchoService,
    AppPreloadingStrategy,
    {provide: APP_INITIALIZER, useFactory: initialize_config, deps: [ConfigService], multi: true },
    {provide: ErrorHandler, useClass: ErrorsHandlerService, },
    {provide: HTTP_INTERCEPTORS, useClass: HttpAuthInterceptor, multi: true},
    { provide: LOCALE_ID, useValue: 'en-in'},
    { provide: MAT_DATE_LOCALE, useValue: 'en-in'},
    {provide: DEFAULT_CURRENCY_CODE, useValue: 'INR'},
    { provide: RollbarService, useFactory: rollbarFactory },
    { provide: HAMMER_GESTURE_CONFIG, useClass: MyHammerConfig },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService
  ],
  entryComponents: [AlertDialogComponent],
  // bootstrap: [AppComponent, MsalRedirectComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
export function initialize_config(configService: ConfigService) {

  return () => configService.initializeConfig();
}
const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
export function loggerCallback(logLevel: LogLevel, message: string) {

}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // clientId: '3fba556e-5d4a-48e3-8e1a-fd57c12cb82e', // PPE testing environment
      // authority: 'https://login.windows-ppe.net/common', // PPE testing environment.
      clientId: OAuthSettings.appId,
      authority: 'https://login.microsoftonline.com/' + OAuthSettings.tenentId + '/',
      // redirectUri: '/user/login',
      // postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Trace,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

import { Inject, Injectable } from '@angular/core';
import { Users } from '../../../manage/users/users';
import { Subject, Observable } from 'rxjs';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { getCircularReplacer } from '../globalfunctions';
import { DOCUMENT } from '@angular/common';
@Injectable()
export class StorageService {
  locWatchId: void;

  constructor(


  ) {

  }
  public static user: Users;
  public static modelRefs = new Array<BsModalRef>();
  public static storageSub = new Subject<any>();
  public static watchStorage(): Observable<any> {
    return StorageService.storageSub.asObservable();
  }
  static getItem(arg0: string): any {
    return localStorage.getItem(arg0);
  }
  public static setItem(key: string, data: any) {
    localStorage.setItem(key, data);
    StorageService.storageSub.next({key: data});
  }
  public static removeItem(key) {
    localStorage.removeItem(key);
    StorageService.storageSub.next('changed');
  }
  public static setToken(token: string) {
    StorageService.setItem('tokenGlasswing', token);
  }
  public static getToken(): string {
    const anItem = localStorage.getItem('tokenGlasswing');
    if (anItem && anItem !== 'undefined' && typeof anItem !== 'undefined' ) {
      return anItem;
    }
    return null;
  }
  public static setUser(user: Users) {
    if(user == null) {
      StorageService.removeItem('userGlasswing');
    }
    else {
      StorageService.setItem('userGlasswing', JSON.stringify(user, getCircularReplacer()));

    }
    StorageService.user = user;
  }

  public static getUser(): Users {
    if (StorageService.user) {
      return StorageService.user;
    }
    const anItem = localStorage.getItem('userGlasswing');
    if (anItem && anItem !== 'undefined' && typeof anItem !== 'undefined' ) {
      try{
        const aUser = JSON.parse(anItem);
        StorageService.user = new Users(aUser);
        return StorageService.user;
      }
      catch(e){
        throw e;
      }
    }
    return null;
  }
  public static getRole(): number {
    const user = StorageService.getUser();
    if (user !== null) {
      return user.roles_id;
    }
    return 0;
  }
  public static checkRole(roles: Array<number>): boolean {
    const user = StorageService.getUser();
    if (user !== null && user.roles_id) {
      const index = roles.indexOf(user.roles_id);
      return index > -1 ? true : false;
    }
    return false;
  }
  public static getWA(): Array<string> {
    const user = StorageService.getUser();
    if (user !== null && user.work_area_cd) {
        return user.work_area_cd;


    }
    return [];
  }
  public static checkWA(was: string): boolean {
    const user = StorageService.getUser();
    if (user !== null && user.work_area_cd) {
      const index = user.work_area_cd.indexOf(was);
      return index > -1 ? true : false;
    }
    return false;
  }
  public static userIsAdmin(): boolean {
    return StorageService.checkRole([10]);
  }
  public static userIsLE(): boolean {
    return StorageService.checkWA('LR EXECUTIVE');
  }
  public static userIsTE(): boolean {
    return StorageService.checkWA('TRAFFIC EXECUTIVE');
  }
  public static userIsCT(): any {
    return StorageService.checkWA('CT EXECUTIVE');
  }
  public static userIsBE(): any {
    return StorageService.checkWA('BOOKING EXECUTIVE');
  }
  public static userIsFinance(): any {
    return StorageService.checkWA('FINANCE EXECUTIVE');
  }
  public static userIsBilling(): any {
    return StorageService.checkWA('BILLING EXECUTIVE');
  }
  public static userIsCoordinator(): any {
    return StorageService.checkWA('COORDINATOR');
  }
  public static userIsROH(): any {
    return StorageService.checkWA('ROH');
  }
  public static userIsOpsHead(): any {
    return StorageService.checkWA('OPS HEAD');
  }
  public static userIsKAM(): any {
    return StorageService.checkWA('KAM');
  }
  public static userIsManagement(): any {
    return StorageService.checkWA('MANAGEMENT');
  }
  public static isLocationUser() {
    return true;
    if ( this.getUser().roles_id === 1 &&
    ( StorageService.userIsCT() || StorageService.userIsTE()
    || StorageService.userIsLE() ) ) {
      return true;
    }
    return false;
  }
  public static checkLocation(){
    if( StorageService.isLoginWithoutLoc() ){
      alert('Unable to get your location. Please switch on you device/mobile location to continue.');
    }
  }
  public static checkCookie(){
    if( window.navigator && !window.navigator.cookieEnabled ){
      alert('Please allow cookies on you device/mobile. Cookies are required for this app to function');
      this.logout();
      return false;
    }
  }
  public static isLogin(): boolean {
    if (
      StorageService.getToken() != null &&
      StorageService.getUser() != null
    ) {
      return true;
    }
    return false;
  }
  public static isLoginWithoutLoc(): boolean {
    if (
      StorageService.getToken() != null &&
      StorageService.getUser() != null
      && !localStorage.getItem('hasLoc')
    ) {
      return true;
    }
    return false;
  }
  public static logout() {
    StorageService.removeItem('tokenGlasswing');
    StorageService.removeItem('userGlasswing');
    StorageService.removeItem('id_token');
    StorageService.removeItem('auth_service');
    StorageService.user = null;
    // StorageService.clearLoc();
    // userGlasswing

  }
  public static saveLocation(coords) {
    StorageService.setItem('lat', coords.latitude + '');
    StorageService.setItem('lng', coords.longitude + '');
    StorageService.setItem('locTime', (new Date()).getTime() + '');
    StorageService.setItem('hasLoc', '1');
    StorageService.setItem('newLocation', '1');
  }
  public static shouldSendLocation() {
    return localStorage.getItem('newLocation') === '1';
  }
  public static locationSend() {
    StorageService.setItem('newLocation', '0');
  }
  public static hasLoc() {
    if ( localStorage.getItem('hasLoc') === '1') {
      return true;
    }
    return false;
  }
  public static getLoc() {
    return [localStorage.getItem('lat'), localStorage.getItem('lng'), localStorage.getItem('locTime')];
  }
  public static clearLoc() {
    StorageService.removeItem('lat');
    StorageService.removeItem('lng');
    StorageService.removeItem('locTime');
    StorageService.removeItem('hasLoc');
    StorageService.removeItem('newLocation');
  }

  public static userCanCreateOrder(){
    return (StorageService.checkRole([1,  10]) && this.checkWA("BOOKING EXECUTIVE"))
  }

}

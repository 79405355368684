import { RouterModule, Routes } from "@angular/router";
import { LoginComponent } from "./user/login/login.component";
import { SignupComponent } from "./user/signup/signup.component";
import { ForgotPasswordComponent } from "./user/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./user/reset-password/reset-password.component";
import { RoleGuard } from "./shared/core/guards/role.guard";
import { employee, admin_customer, onlyAdmin } from "./shared/core/constants";
import { ProfileComponent } from "./user/profile/profile.component";

export const appRoutes: Routes = [
  {
    path: "user",
    children: [
      { path: "login", component: LoginComponent },
      { path: "signup", component: SignupComponent },
      { path: "forgot-password", component: ForgotPasswordComponent },
      { path: "reset-password/:email", component: ResetPasswordComponent },
      { path: "profile", component: ProfileComponent },
    ],
  },

  {
    path: "bookings",
    loadChildren: () =>
      import("./manage/bookings/bookings.module").then((m) => m.BookingsModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },

  {
    path: "consignmentNotes",
    loadChildren: () =>
      import("./manage/consignmentNotes/consignmentNotes.module").then(
        (m) => m.ConsignmentNotesModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "new-design",
    loadChildren: () =>
      import("./figma-design/figma-design.module").then(
        (m) => m.FigmaDesignModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },

  // { path: 'customers', loadChildren: './manage/customers/customers.module#CustomersModule' },
  // { path: 'bookings', loadChildren: './manage/bookings/bookings.module#BookingsModule' },
  {
    path: "webexpressActivityLog",
    loadChildren: () =>
      import(
        "./manage/webexpressActivityLog/webexpressActivityLog.module"
      ).then((m) => m.WebexpressActivityLogModule),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "usersGroup",
    loadChildren: () =>
      import("./manage/usersGroup/usersGroup.module").then(
        (m) => m.usersGroupModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "userDeviceTokens",
    loadChildren: () =>
      import("./manage/userDeviceTokens/userDeviceTokens.module").then(
        (m) => m.UserDeviceTokensModule
      ),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "goals",
    loadChildren: () =>
      import("./manage/goals/goals.module").then((m) => m.GoalsModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "userDeviceData",
    loadChildren: () =>
      import("./manage/userDeviceData/userDeviceData.module").then(
        (m) => m.UserDeviceDataModule
      ),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "userBidHistory",
    loadChildren: () =>
      import("./manage/userBidHistory/userBidHistory.module").then(
        (m) => m.UserBidHistoryModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "trucks-nearby",
    loadChildren: () =>
      import("./manage/trucks-nearby/trucks.module").then(
        (m) => m.TrucksModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "truckLocation",
    loadChildren: () =>
      import("./manage/truckLocation/truckLocation.module").then(
        (m) => m.TruckLocationModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "thcPrint",
    loadChildren: () =>
      import("./manage/thcPrint/thcPrint.module").then((m) => m.THCPrintModule),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "quotes",
    loadChildren: () =>
      import("./manage/quotes/quotes.module").then((m) => m.QuotesModule),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "bids",
    loadChildren: () =>
      import("./manage/bids/bids.module").then((m) => m.bidsModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "pricePredict",
    loadChildren: () =>
      import("./manage/pricePredict/pricePredict.module").then(
        (m) => m.pricePredictModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  // { path: 'consignmentNotesPrint', loadChildren: './manage/consignmentNotesPrint/consignmentNotesPrint.module#ConsignmentNotesPrintModule', canActivate:[RoleGuard], data:{ roles: employee} },
  {
    path: "thcPayments",
    loadChildren: () =>
      import("./manage/thcPayments/thcPayments.module").then(
        (m) => m.ThcPaymentsModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "partnerBanks",
    loadChildren: () =>
      import("./manage/partnerBanks/partnerBanks.module").then(
        (m) => m.PartnerBanksModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },

  {
    path: "tasks",
    loadChildren: () =>
      import("./manage/tasks/tasks.module").then((m) => m.TasksModule),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "states",
    loadChildren: () =>
      import("./manage/states/states.module").then((m) => m.StatesModule),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "stateLocationMappings",
    loadChildren: () =>
      import(
        "./manage/stateLocationMappings/stateLocationMappings.module"
      ).then((m) => m.StateLocationMappingsModule),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "socialAccounts",
    loadChildren: () =>
      import("./manage/socialAccounts/socialAccounts.module").then(
        (m) => m.SocialAccountsModule
      ),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "sessions",
    loadChildren: () =>
      import("./manage/sessions/sessions.module").then((m) => m.SessionsModule),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "dropdowns",
    loadChildren: () =>
      import("./manage/dropdowns2/dropdowns.module").then(
        (m) => m.DropdownsModule
      ),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "audit",
    loadChildren: () =>
      import("./manage/audits/audits.module").then((m) => m.AuditsModule),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "nearby_drivers-partners",
    loadChildren: () =>
      import(
        "./manage/nearby_drivers-partners/nearby_drivers-partners.module"
      ).then((m) => m.NearbyDriversPartnersModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "consignmentTripTrackings",
    loadChildren: () =>
      import(
        "./manage/consignmentTripTrackings/consignmentTripTrackings.module"
      ).then((m) => m.ConsignmentTripTrackingsModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },

  {
    path: "roles",
    loadChildren: () =>
      import("./manage/roles/roles.module").then((m) => m.RolesModule),
    canActivate: [RoleGuard],
    data: { roles: onlyAdmin },
  },
  {
    path: "users",
    loadChildren: () =>
      import("./manage/users/users.module").then((m) => m.UsersModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "contracts",
    loadChildren: () =>
      import("./manage/contracts/contracts.module").then(
        (m) => m.ContractsModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "1",
    loadChildren: () =>
      import("./manage/invoices/invoices.module").then((m) => m.InvoicesModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "payments",
    loadChildren: () =>
      import("./manage/payments/payments.module").then((m) => m.PaymentsModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "paymentLines",
    loadChildren: () =>
      import("./manage/paymentLines/paymentLines.module").then(
        (m) => m.PaymentLinesModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  // PaymentLinesModule
  {
    path: "customers",
    loadChildren: () =>
      import("./manage/customers/customers.module").then(
        (m) => m.CustomersModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "trucks",
    loadChildren: () =>
      import("./manage/trucks/trucks.module").then((m) => m.TrucksModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "truckHireChallans",
    loadChildren: () =>
      import("./manage/truckHireChallans/truckHireChallans.module").then(
        (m) => m.TruckHireChallansModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },

  {
    path: "drivers",
    loadChildren: () =>
      import("./manage/drivers/drivers.module").then((m) => m.DriversModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "partners",
    loadChildren: () =>
      import("./manage/partners/partners.module").then((m) => m.PartnersModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "consignmentStatusTrackings",
    loadChildren: () =>
      import(
        "./manage/consignmentStatusTrackings/consignmentStatusTrackings.module"
      ).then((m) => m.ConsignmentStatusTrackingsModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },

  // { path: 'consignmentNotes', loadChildren: () => import('./manage/consignmentNotes/consignmentNotes.module').then(m => m.ConsignmentNotesModule), canActivate:[RoleGuard], data:{ roles: employee, preload: true, delay: true} },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./manage/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [RoleGuard],
    data: { roles: admin_customer },
  },
  {
    path: "customer-bookings",
    loadChildren: () =>
      import("./manage/customer.bookings/customer.bookings.module").then(
        (m) => m.CustomerBookingsModule
      ),
    canActivate: [RoleGuard],
    data: { roles: admin_customer },
  },
  // { path: 'customer-trips', loadChildren: () => import('./manage/customer.trips/customer.trips.module').then(m => m.CustomerTripsModule), canActivate:[RoleGuard], data:{ roles: admin_customer} },
  {
    path: "reports",
    loadChildren: () =>
      import("./manage/reports/reports.module").then((m) => m.ReportsModule),
    canActivate: [RoleGuard],
    data: { roles: admin_customer },
  },
  {
    path: "trips",
    loadChildren: () =>
      import("./manage/trips/trips.module").then((m) => m.TripsModule),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "transporters",
    loadChildren: () =>
      import("./manage/transporters/transporters.module").then(
        (m) => m.TransportersModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },

  // { path: 'cancel', component: CancelBookingsComponent, canActivate:[RoleGuard], data:{ roles: admin_customer}},
  // {path: 'support', component: SupportComponent, canActivate:[RoleGuard], data:{ roles: admin_customer} },

  {
    path: "tripStatusTrackings",
    loadChildren: () =>
      import("./manage/tripStatusTrackings/tripStatusTrackings.module").then(
        (m) => m.TripStatusTrackingsModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },
  {
    path: "thcStatusTrackings",
    loadChildren: () =>
      import("./manage/thcStatusTrackings/thcStatusTrackings.module").then(
        (m) => m.ThcStatusTrackingsModule
      ),
    canActivate: [RoleGuard],
    data: { roles: employee },
  },

  { path: '1', loadChildren: () =>
  import("./manage/triplite/triplite.module").then(
    (m) => m.TripliteModule
  ), canActivate:[RoleGuard], data:{ roles: admin_customer} },

  // { path: 'consignmentNotes', loadChildren: './manage/consignmentNotes/consignmentNotes.module#ConsignmentNotesModule', canActivate:[RoleGuard], data:{ roles: employee} },

  // { path: 'dropdowns', loadChildren: './manage/dropdowns/dropdowns.module#DropdownsModule', canActivate:[RoleGuard], data:{ roles: employee} },
  // { path: 'roles', loadChildren: './manage/audits/audits.module#AuditsModule', canActivate:[RoleGuard], data:{ roles: employee} },

  {
    path: "1",
    loadChildren: () =>
      import("./manage/driverTrips/driverTrips.module").then(
        (m) => m.DriverTripsModule
      ),
  },

  {
    path: "tenew",
    loadChildren: () =>
      import("./manage/tenew/tenew.module").then((m) => m.TenewModule),
  },
  {
    path: "entity",
    loadChildren: () =>
      import("./manage/entity/entity.module").then((m) => m.EntityModule),
  },
  { path: "**", redirectTo: "user/login", pathMatch: "full" },
];

/// <reference types="gapi" />
import { Observable, Observer, Subject } from 'rxjs';
import { BehaviorSubject } from 'rxjs';
import { Users } from '../../../manage/users/users';
import { Inject, Injectable, NgZone } from '@angular/core';
import { HttpService } from './http-service.service';
import { DialogService } from '../../layout/dialogs/services/dialog.service';
import { StorageService } from './storage.service';
import {  MsalBroadcastService, MsalService } from '@azure/msal-angular';
import * as Rollbar from 'rollbar';
import { RollbarService } from './rollbar.service';
import { getCircularReplacer } from '../globalfunctions';
import { AppSettings } from '../config';
import { EventMessage, EventPayload, EventType } from '@azure/msal-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
declare var gapi: any;

@Injectable()
export class AuthenticatorService {
  public auth2: any;
  public selectedMenuName = new BehaviorSubject('');
  public user$: BehaviorSubject<Users> = new BehaviorSubject<Users>(null as any);
  public isLoggedIn$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isInternalErp$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  public isError$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public hasLocation$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  public isLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );
  private readonly _destroying$ = new Subject<void>();
  public googleUser: any;
  isIframe!: boolean;
  locationWatchId!: number;
  constructor(
    private zone: NgZone,
    private serviceHttp: HttpService,
    private _dialogService: DialogService,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    @Inject(RollbarService) private rollbar: Rollbar,
    @Inject(DOCUMENT) private document: Document

  ) {

    if(StorageService.isLogin()) {
      const user = StorageService.getUser()
      this.user$.next(user);
      this.isLoggedIn$.next(true);
      this.isError$.next(null);
      this.enableRollbar();
      this.watchLocation();

    }
    else {
      this.user$.next(null as any);
      this.isLoggedIn$.next(false);
      this.isError$.next(null);
      this.stopWatch();
      this.disableRollbar();
    }
    let aSubscription = this.broadcastService.inProgress$.subscribe(
      (payload) => {

    });

    this.broadcastService.msalSubject$
    .pipe(
        filter( (msg: EventMessage) => {
          // msg.eventType === EventType.LOGIN_SUCCESS);

          return (msg.eventType === EventType.LOGIN_SUCCESS || msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS);
        },
        takeUntil(this._destroying$)
    ))
    .subscribe((result) => {

        // this.checkAccount();
        // this.validateMsIDToken(result?.payload?.idToken);



        this.checkMsAccount(result.payload);
        // const idToken = (<any>result.payload)?.idToken;
        // this.validateMsIDToken(result.payload);
        // let aAccount = this.msalService.instance.getActiveAccount();
        //

        //
        // this.checkMsAccount();
    });
    this.checkIfInternalErp();
  }
  checkIfInternalErp() {

    this.isInternalErp$.next(this.document.location.hostname == 'ierp.glasswing.in' ||
    this.document.location.hostname == 'ierpdev.glasswing.in' ||this.document.location.port == '4500');
  }
  //
  // isInternalErp(){
  //   return this.isInternalEr
  //   // return this.document.location.hostname == 'ierp.glasswing.in' || this.document.location.port == '4500';

  // }
  validateToken(token: string, msToken?: any): Observable<any> {
    let param:any =  {googleToken: token,msToken: msToken}
    return this.serviceHttp.getResponse('validationApi', 'POST', 0, param);
  }
  validateIDToken(id_token:any) {
    this.validateToken(id_token).subscribe(
      response => {
        if (response.status === 200) {
          this.postIDValidation(response, id_token, 'GOOGLE');
        } else {
          this.isError$.next(response);
        }
      },
      error => {
     //
        this.isError$.next(error);
      }
    );
  }
  signInByWeb(data:any){
    let user:any = new Users(data);
    this.serviceHttp.getResponse('login', 'POST', 0, user).subscribe(
      response => {
        //
        if (response.status == 200) {
          this.postIDValidation(response, response.data.token, 'WEB');
          // this.checkInfoAndTakeToLandingPage();
        } else {
          // this.errorMessage = JSON.stringify(response.message);
          // this.errorMessage = appGlobals.getDisplayErrorMessage(response);
          // alert(this.errorMessage);
          //
          this.isError$.next(response);
        }
      },
      error => {
     //
        this.isError$.next(error);
        // alert(this.errorMessage);
      },
      () => {}
    );
  }
  signInByGoogle(): void {
    const idToken = StorageService.getItem('id_token');
    //
    if (!idToken || idToken == null) {
      gapi.auth2.getAuthInstance().signIn().then(
        (user:any) => {
          //
          this.googleUser = user;
          this.validateIDToken(user.getAuthResponse().id_token);
        },
        (error:any) => {
       //
          this.isError$.next(error);
        }
      );
    } else {
      this.validateIDToken(idToken);
    }
  }
  validateMsIDToken(id_token:any) {
    this.validateToken('', id_token).subscribe(
      response => {
        //
        if (response.status === 200) {
          this.postIDValidation(response, id_token, 'MSOFFICE');
        } else {
          this.isError$.next(response);
          this._dialogService.openAlert({
            title: 'Error',
            message: response.message
          });
        }
      },
      error => {
     //
        this.isError$.next(error);
        this._dialogService.openAlert({
          title: 'Error',
          message: error
        });
      }
    );
  }
  postIDValidation(response:any, id_token:any, auth_service:any){
    this.zone.run(() => {
      const user = new Users(response.data.user);
      StorageService.setToken(response.data.token);
      StorageService.setItem('id_token', id_token);
      StorageService.setItem('auth_service', auth_service);
      StorageService.setUser(response.data.user);
      if(response.data.customer_contact) {
        StorageService.setItem('customer_contact', JSON.stringify(response.data.customer_contact, getCircularReplacer()));
        StorageService.setItem('company_name', (response.data.customer_contact.customer.name_short | response.data.customer_contact.customer.name));
        StorageService.setItem('customer_id',response.data.customer_contact.customers_id);
      }
      if(response.data.partner_contact) {
        StorageService.setItem('partner_contact', JSON.stringify(response.data.partner_contact, getCircularReplacer()));
        StorageService.setItem('company_name', (response.data.partner_contact.partner.name_short | response.data.partner_contact.partner.name));
      }
      this.user$.next(user);
      this.isLoggedIn$.next(true);
      this.isError$.next(null);
      this.enableRollbar();
      this.watchLocation();

    });

  }
  watchLocation(){
    this.getLocationObserver().subscribe(
      position => {
        //
        //
        //
        StorageService.saveLocation(position.coords);
      },
      error => {
        let result = true;
        //
        switch (error.code) {
          case error.PERMISSION_DENIED:
            result = false;
            this._dialogService.openAlert({
              title: 'Error',
              message:
                'Location services are required for ' +
                'this system to work. Please contact system administrator.'
            });
            this.signOut();
            break;
          case error.POSITION_UNAVAILABLE:
          this._dialogService.openAlert({
            title: 'Error',
            message:
              'Location information is unavailable. Please switch on location in settings.'
          });
            //
            break;
          case error.TIMEOUT:
         //
            break;
        }
        return result;
      }
    );
  }
  checkMsAccount(aUserToken?: EventPayload) {

    // let msUser = this.msalService.getAccount();
    // if(!!msUser) {
    //   this.validateMsIDToken(msUser);
    // }
    this.validateMsIDToken(aUserToken);
    // create user

    // this.user$.next();
    // this.loggedIn = !!this.msalService.getAccount();
  }

  setUpMicrosoftLogin(){
    this.isIframe = window !== window.parent && !window.opener;
    // this.checkAccount();

    // this.msalService.subscribe('msal:loginSuccess', () => {
    //   //
    //   this.checkMsAccount();
    // });
    this.msalService.getLogger().verbose("MsalRedirectComponent activated");
    this.msalService.handleRedirectObservable().subscribe(
      (result)=> {

      },
      (error)=> {

      },
      );


    // let aSubscription = this.broadcastService.inProgress$.subscribe(
    //   (payload) => {
    //
    // });

    // this.broadcastService.msalSubject$
    // .pipe(
    //     filter( (msg: EventMessage) => {
    //       // msg.eventType === EventType.LOGIN_SUCCESS);
    //
    //       return true;
    //     },
    //     takeUntil(this._destroying$)
    // ))
    // .subscribe((result) => {
    //
    //     // this.checkAccount();
    //     // this.validateMsIDToken(result?.payload?.idToken);
    //
    //
    //     let aAccount = this.msalService.instance.getActiveAccount();
    //

    //
    //     this.checkMsAccount();
    // });


    // ((authError:any, response:any) => {
    //   if (authError) {
    //  //
    //     return;
    //   }
    //   //
    // });
  }
  async signInByMicrosoft(): Promise<void> {
    this.setUpMicrosoftLogin();
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;
    if (isIE) {
      this.msalService.loginRedirect();
    } else {
      this.msalService.loginPopup();
    }
  }
  signOutByMicrosoft(): void {
 //
    StorageService.logout();
    this.isLoggedIn$.next(false);
    this.isError$.next(null);
    this.user$.next(null as any);
    this.stopWatch();
    this.disableRollbar();
    // this.msalService.logout();
  }
  signOutByGoogle(){
    if(gapi !== null && (typeof gapi !== undefined)) {
      gapi.auth2.getAuthInstance().signOut().then(
        () => {
          this.zone.run(() => {
            this.isLoggedIn$.next(false);
            this.user$.next(null as any);
            this.isError$.next(null);
            this.stopWatch();
            this.disableRollbar();
            gapi.auth2.getAuthInstance().disconnect().then(
              () => {
                //
              }
            );
         //
          });
        },
        (err:any) => {
          alert(err);
       //
        }
      );
    }
  }
  signOut(): void {
    const auth_service = StorageService.getItem('auth_service');
    if(auth_service == 'MSOFFICE') {
      this.signOutByMicrosoft();
    }
    else if(auth_service == 'GOOGLE') {
      this.signOutByGoogle();
    }

    this.isLoggedIn$.next(false);
    this.isError$.next(null);
    this.user$.next(null as any);
    StorageService.logout();
    this.disableRollbar();
    this.stopWatch();


  }
  initClient(obj:any) {
    //
    gapi.client
      .init({
        apiKey: 'AIzaSyAO_1Na7WlZgBWGKYyNGb2UmX2qtMk4CRc',
        discoveryDocs: [
          'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
        ],
        clientId:
          '764132605015-m7oavmif1o2q1s7mqfgo48pehhnv5lke.apps.googleusercontent.com',
        scope: 'email profile https://www.googleapis.com/auth/drive'
      })
      .then(
        function() {
          //
          obj.zone.run(() => {
            // this.auth2 = auth;

            obj.isLoaded$.next(true);
          });
        },
        (error:any) => {
       //
       //
        }
      );
  }
  initAuth2(obj:any) {
    const loadClientFuntion = obj.loadAuth2Client;
    gapi.auth2
    .init({
      client_id:
        '764132605015-m7oavmif1o2q1s7mqfgo48pehhnv5lke.apps.googleusercontent.com',
      scope: 'email profile https://www.googleapis.com/auth/drive'
    })
    .then(
      (auth:any) => {
        //
       // obj.zone.run(() => {
          obj.auth2 = auth;
          loadClientFuntion(obj);
          // this.isLoaded$.next(true);
        // });
      },
      (error:any) => {
     //
     //
      }
    );
  }
  loadAuth2Client(obj:any) {
    const initClientFuntion = obj.initClient;
    if(!gapi.client || gapi.client === undefined){
      //
      gapi.load(
        'client:auth2',
        initClientFuntion(obj),
        (onerror:any) =>
          function() {
         //
          }
      );
    }
    else {
      //
      initClientFuntion(obj);
    }
  }
  loadAuth2(): void {
    // gapi.load('auth2', this.initAuth());
    //
    const initAuthFunction = this.initAuth2;
    if(!gapi.auth2) {
      gapi.load(
        'auth2',
        () => {
          //
          initAuthFunction(this);
        },
        (onerror:any) =>
          function() {
            // Handle loading error.
         //
          }
      );
    }
    else {
      //
      initAuthFunction(this);
    }
  }
  handleClientLoad() {
    // Loads the client library and the auth2 library together for efficiency.
    // Loading the auth2 library is optional here since `gapi.client.init` function will load
    // it if not already loaded. Loading it upfront can save one network request.
    if(typeof gapi !== undefined && gapi && gapi !== null ) {
      gapi.load('client:auth2', this.initClientNew(this));
    }

  }
  initClientNew(obj:any) {
    // Initialize the client with API key and People API, and initialize OAuth with an
    // OAuth 2.0 client ID and scopes (space delimited string) to request access.
    //
    if( !gapi.client) {
      return;
    }
    gapi.client.init({
      apiKey: 'AIzaSyAO_1Na7WlZgBWGKYyNGb2UmX2qtMk4CRc',
      discoveryDocs: [
        'https://www.googleapis.com/discovery/v1/apis/drive/v3/rest'
      ],
      clientId:
        '764132605015-m7oavmif1o2q1s7mqfgo48pehhnv5lke.apps.googleusercontent.com',
      scope: 'email profile https://www.googleapis.com/auth/drive'
    }).then(function () {
      // Listen for sign-in state changes.
      // gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

      // Handle the initial sign-in state.
      // updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
      obj.zone.run(() => {
        obj.auth2 = gapi.auth2;
         // loadClientFuntion(obj);
         obj.isLoaded$.next(true);
       });
    });
  }
  loadAuth2New(): void {
    // gapi.load('auth2', this.initAuth());
    //
    // this.handleClientLoad();
    // if(!gapi.auth2 || gapi.auth2 === undefined) {
    //   gapi.load(
    //     'auth2',
    //     () => {
            //
    //       gapi.auth2
    //       .init({
    //         client_id:
    //           '764132605015-m7oavmif1o2q1s7mqfgo48pehhnv5lke.apps.googleusercontent.com',
    //         scope: 'email profile https://www.googleapis.com/auth/drive'
    //       })
    //       .then(
    //         auth => {
    //
    //           this.zone.run(() => {
    //               this.auth2 = auth;
    //               loadClientFuntion(obj);
    //               // this.isLoaded$.next(true);
    //           });
    //         },
    //         error => {
    //
    //
    //         }
    //       );
    //     },
    //     onerror =>
    //       function() {
    //         // Handle loading error.
    //
    //       }
    //   );
    // }
    // else {
    //
    //   // initAuthFunction(this);
    // }
  }
  stopWatch() {
    if(this.locationWatchId && window.navigator && window.navigator.geolocation){
      window.navigator.geolocation.clearWatch(this.locationWatchId);
    }

 }
  getLocationObserver(): Observable<any> {
    return new Observable((observer: Observer<any>) => {
        if(window.navigator && window.navigator.geolocation) {
            this.locationWatchId = window.navigator.geolocation.watchPosition(
                (position) => {
                    observer.next(position);
                    // this.hasLocation$.next(position);
                    observer.complete();
                },
                (error) => {
                  observer.error(error);
                }
            );
        } else {
            observer.error('Unsupported Browser');
        }
    });
  }
  disableRollbar(){
    // this.rollbar.configure({
    //   enabled: false,
    // });
  }
  enableRollbar(){
    if(AppSettings.SERVER_ENDPOINT.indexOf('https://api.glasswing.in') === 0){
          // if(environment.production){
          //   this.rollbar.configure({
          //       enabled: false,
          //       autoInstrument: {
          //         log: false,
          //       },
          //       payload: {
          //         person: {
          //           id: this.user$.value.id,
          //           username: this.user$.value.name,
          //           email: this.user$.value.email,
          //           location: StorageService.getLoc()
          //         }
          //       }
          //     });
          //     //
          //     //
          //   }
          //   else {
          //  //
          //   }
      //   }
      //   else {
      //  //
      //   }

        }
  }
}
